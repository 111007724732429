import { Commit } from "vuex";
import { api } from "@/api/Api";
import {
  IUserAccount,
  IUserLoginHistory
} from "@/store/interfaces/accounts/IUserAccount";
import {
  accountsFilterSchema,
  accountsQuery
} from "@/api/interfaces/accounts/filters";
import { IAccountsResponse } from "@/api/interfaces/accounts/common";
import { AddUserDto, EditUserDto } from "@/api/interfaces/users/users.dto";
import { IApiResponse } from "@/api/interfaces/IApi";
import { AxiosResponse } from "axios";

export interface accountsState {
  accountsList: IUserAccount[];
  totalAccounts: number;
  userInfo?: IUserAccount;
  userLogins?: IUserLoginHistory[];
}
export const usersModule = {
  state(): accountsState {
    return {
      accountsList: [],
      totalAccounts: 0
    };
  },

  getters: {
    accountsList: ({ accountsList }: accountsState): IUserAccount[] => {
      return [...accountsList];
    },
    userInformation: ({
      userInfo
    }: accountsState): IUserAccount | undefined => {
      return userInfo;
    },
    userLogins: ({
      userLogins
    }: accountsState): IUserLoginHistory[] | undefined => {
      return userLogins;
    },
    totalAccounts: ({ totalAccounts }: accountsState): number => totalAccounts
  },

  mutations: {
    setAccounts(state: accountsState, accounts: IUserAccount[]) {
      state.accountsList = accounts;
    },
    setUserLoginHistory(state: accountsState, logins: IUserLoginHistory[]) {
      state.userLogins = logins;
    },
    setUserInfo(state: accountsState, account: IUserAccount) {
      console.log(account)
      state.userInfo = account;
    },
    setTotalAccounts(state: accountsState, total: number) {
      state.totalAccounts = total;
    }
  },

  actions: {
    async fetchAccountsList(
      { commit }: { commit: Commit },
      { query, filter }: { query: accountsQuery; filter: accountsFilterSchema }
    ): Promise<IAccountsResponse> {
      const response: IAccountsResponse = await api.fetchAccounts(
        query,
        filter
      );

      const { data, total } = response;

      if (data) {
        commit("setAccounts", data);
      }
      if (total) {
        commit("setTotalAccounts", total);
      }

      return response;
    },

    async fetchAllUsersList({
      commit
    }: {
      commit: Commit;
    }): Promise<IAccountsResponse> {
      const response: IAccountsResponse = await api.fetchAllAccounts();

      const { data, total } = response;

      if (data) {
        commit("setAccounts", data);
      }
      if (total) {
        commit("setTotalAccounts", total);
      }

      return response;
    },

    async fetchLoginHistory(
      { commit }: { commit: Commit },
      { id, query }: { id: string; query: accountsQuery }
    ): Promise<IAccountsResponse> {
      const response: IAccountsResponse = await api.fetchLoginHistory(
        id,
        query
      );

      const { data } = response;

      if (data) {
        commit("setUserLoginHistory", data);
      }

      return response;
    },

    async fetchUserInfo(
      { commit }: { commit: Commit },
      { id }: { id: string }
    ): Promise<IAccountsResponse> {
      const response: IAccountsResponse = await api.fetchUser(id);
      const { data } = response;

      if (data) {
        commit("setUserInfo", data);
      }

      return response;
    },

    async addUser(
      { commit }: { commit: Commit },
      { data: reqData }: { data: AddUserDto }
    ): Promise<IApiResponse> {
      return await api.addUser(reqData);
    },

    async editUser(
      { commit }: { commit: Commit },
      { data: reqData }: { data: EditUserDto }
    ): Promise<IApiResponse> {
      return await api.editUser(reqData);
    },

    async removeUser(
      _: any,
      { id }: { id: number }
    ): Promise<IAccountsResponse> {
      return await api.removeUser(id);
    },

    async setActive(
      _: any,
      { id, param }: { id: number; param: boolean }
    ): Promise<IAccountsResponse> {
      return await api.setActive(id, param);
    },
    async setBlocked(
      { commit }: { commit: Commit },
      { id, param }: { id: number; param: boolean }
    ): Promise<IAccountsResponse> {
      return await api.setBlocked(id, param);
    },

    async uploadUserAvatar(
      { commit }: { commit: Commit },
      { id, avatar }: { id: number; avatar: File }
    ): Promise<IApiResponse> {
      return await api.uploadUserAvatar(id, avatar);
    }
  }
};
