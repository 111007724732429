import { IEsimDirectoryDefault } from "./../api/interfaces/esim-directory/common";
import {
  esimDirectoriesGetLinks,
  esimDirectoriesTypes,
  TEsimDirectoryTypes
} from "./../hooks/esim/directories/types/esimDirectories.types";
import {
  IBundle,
  IDataType,
  INetworkGeneration,
  IPlanShort,
  IPurchaseType,
  IValidity,
  IZone
} from "@/api/services/packages/esim/location-packages/types";

import { Commit, GetterTree } from "vuex";
import { api } from "@/api/Api";
import { rootState } from "@/store/rootState";
import { esimType, IEsimServer } from "@/api/services/profiles/profiles.types";
export interface IEsimDirectoryState {
  esimDirectory: {
    [esimDirectoriesTypes.validityPeriod]: {
      value: IEsimDirectoryDefault[];
      total: number;
    };
    [esimDirectoriesTypes.purchaseType]: {
      value: IEsimDirectoryDefault[];
      total: number;
    };
    [esimDirectoriesTypes.dataTypes]: {
      value: IEsimDirectoryDefault[];
      total: number;
    };
    [esimDirectoriesTypes.networkGeneration]: {
      value: IEsimDirectoryDefault[];
      total: number;
    };
    [esimDirectoriesTypes.plans]: {
      value: IEsimDirectoryDefault[];
      total: number;
    };
    [esimDirectoriesTypes.trafficAmount]: {
      value: IEsimDirectoryDefault[];
      total: number;
    };
  };
}
export interface esimDirectoryState extends IEsimDirectoryState {
  dataTypes: IDataType[];
  bundles: IBundle[];
  purchaseTypes: IPurchaseType[];
  validityTypes: IValidity[];
  networkGenerations: INetworkGeneration[];
  esimZones: IZone[];
  esimTypes: esimType[];
  esimServers: IEsimServer[];
  plans: IPlanShort[];
}
const initialStateV2: IEsimDirectoryState = {
  esimDirectory: {
    [esimDirectoriesTypes.validityPeriod]: {
      value: [],
      total: 0
    },
    [esimDirectoriesTypes.purchaseType]: {
      value: [],
      total: 0
    },
    [esimDirectoriesTypes.dataTypes]: {
      value: [],
      total: 0
    },
    [esimDirectoriesTypes.networkGeneration]: {
      value: [],
      total: 0
    },
    [esimDirectoriesTypes.plans]: {
      value: [],
      total: 0
    },
    [esimDirectoriesTypes.trafficAmount]: {
      value: [],
      total: 0
    }
  }
};
const initialState: esimDirectoryState = {
  dataTypes: [],
  bundles: [],
  purchaseTypes: [],
  validityTypes: [],
  networkGenerations: [],
  esimZones: [],
  esimTypes: [],
  esimServers: [],
  plans: [],
  esimDirectory: initialStateV2.esimDirectory
};

export const esimDirectory = {
  state(): esimDirectoryState {
    return {
      ...initialState
    };
  },

  mutations: {
    setDataTypes(state: esimDirectoryState, dataTypes: IDataType[]) {
      state.dataTypes = dataTypes;
    },
    setBundles(state: esimDirectoryState, bundles: IBundle[]) {
      state.bundles = bundles;
    },
    setPurchaseTypes(
      state: esimDirectoryState,
      purchaseTypes: IPurchaseType[]
    ) {
      state.purchaseTypes = purchaseTypes;
    },
    setValidityTypes(state: esimDirectoryState, validityTypes: IValidity[]) {
      state.validityTypes = validityTypes.filter(({ active }) => active);
    },

    setNetworkGenerations(
      state: esimDirectoryState,
      networkGenerations: INetworkGeneration[]
    ) {
      state.networkGenerations = networkGenerations;
    },
    setEsimZones(state: esimDirectoryState, esimZones: IZone[]) {
      state.esimZones = esimZones;
    },
    setEsimTypes(state: esimDirectoryState, esimTypes: esimType[]) {
      state.esimTypes = esimTypes;
    },
    setPlans(state: esimDirectoryState, plans: IPlanShort[]) {
      state.plans = plans;
    },
    setEsimDirectoryData(
      state: esimDirectoryState,
      {
        data,
        type
      }: { data: IEsimDirectoryDefault[]; type: TEsimDirectoryTypes }
    ) {
      state.esimDirectory[type].value = data;
      state.esimDirectory[type].total = data.length;
    }
  },

  getters: {
    dataTypes: ({ dataTypes }: esimDirectoryState): IDataType[] =>
      dataTypes.filter(({ active }) => active),
    bundles: ({ bundles }: esimDirectoryState): IBundle[] => bundles,
    purchaseTypes: ({ purchaseTypes }: esimDirectoryState): IPurchaseType[] =>
      purchaseTypes,
    validityTypes: ({ validityTypes }: esimDirectoryState): IValidity[] =>
      validityTypes.filter(({ active }) => active),
    networkGenerations: ({
      networkGenerations
    }: esimDirectoryState): INetworkGeneration[] => networkGenerations,
    esimZones: ({ esimZones }: esimDirectoryState): IZone[] => esimZones,
    esimTypes: ({ esimTypes }: esimDirectoryState): esimType[] => esimTypes,
    esimPlansParrent: ({ plans }: esimDirectoryState): IPlanShort[] => plans,
    esimDirectoriesList: ({ esimDirectory }: esimDirectoryState) => {
      return (directoryType: TEsimDirectoryTypes) =>
        esimDirectory[directoryType].value;
    },
    esimDirectoriesTotal: ({ esimDirectory }: esimDirectoryState) => {
      return (directoryType: TEsimDirectoryTypes) =>
        esimDirectory[directoryType].total;
    }
  },

  actions: {
    async fetchBundles({
      getters,
      commit
    }: {
      getters: GetterTree<esimDirectoryState, IBundle[]>;
      commit: Commit;
    }) {
      const bundles = getters.bundles;

      if (bundles.length) {
        return { success: true };
      }

      const response = await api.fetchBundles();
      commit("setBundles", response.data.bundles);

      return { ...response, data: response.data.bundles };
    },

    async fetchDataTypes({
      getters,
      commit
    }: {
      getters: GetterTree<esimDirectoryState, IDataType[]>;
      commit: Commit;
    }) {
      const dataTypes = getters.dataTypes;

      if (dataTypes.length) {
        return { success: true };
      }

      const { data, ...response } = await api.fetchDataTypes();
      commit("setDataTypes", data);

      return { ...response, data };
    },

    async fetchPurchaseTypes({
      getters,
      commit
    }: {
      getters: GetterTree<esimDirectoryState, IPurchaseType[]>;
      commit: Commit;
    }) {
      const dataTypes = getters.purchaseTypes;

      if (dataTypes.length) {
        return { success: true };
      }

      const { data, ...response } = await api.fetchPurchaseTypes();
      commit("setPurchaseTypes", data);

      return { ...response, data };
    },

    async fetchValidityTypes({
      getters,
      commit
    }: {
      getters: GetterTree<esimDirectoryState, rootState>;
      commit: Commit;
    }) {
      const dataTypes = getters.validityTypes;

      if (dataTypes.length) {
        return { success: true };
      }

      const { data, ...response } = await api.fetchValidityTypes();
      commit("setValidityTypes", data);

      return { ...response, data };
    },

    async fetchNetworkGenerations({
      getters,
      commit
    }: {
      getters: GetterTree<esimDirectoryState, rootState>;
      commit: Commit;
    }) {
      const networkGenerations = getters.networkGenerations;

      if (networkGenerations.length) {
        return { success: true };
      }

      const { data, ...response } = await api.fetchNetworkGenerations();

      commit("setNetworkGenerations", data);
      return { ...response, data };
    },
    async fetchEsimZones({
      getters,
      commit
    }: {
      getters: GetterTree<esimDirectoryState, rootState>;
      commit: Commit;
    }) {
      const networkGenerations = getters.networkGenerations;

      if (networkGenerations.length) {
        return { success: true };
      }

      const { data, ...response } = await api.fetchEsimZones();
      commit("setEsimZones", data);
      return { ...response, data };
    },
    async fetchEsimTypes({
      getters,
      commit
    }: {
      getters: GetterTree<esimDirectoryState, rootState>;
      commit: Commit;
    }) {
      const networkGenerations = getters.networkGenerations;

      if (networkGenerations.length) {
        return { success: true };
      }

      const { data, ...response } = await api.fetchEsimTypes();
      commit("setEsimTypes", data);
      return { ...response, data };
    },
    async fetchPlans({ commit }: { commit: Commit }) {
      const { data, ...response } = await api.fetchPlans();
      commit("setPlans", data);
      return { ...response, data };
    },

    async fetchDataDirectory(
      { commit }: { commit: Commit },
      type: TEsimDirectoryTypes
    ) {
      const { data, ...response } = await api.fetchEsimDirectoryData(
        esimDirectoriesGetLinks[type]
      );
      commit("setEsimDirectoryData", { data, type });
      return response;
    },
    async uploadEsimDirectoryImage(
      _: any,
      { file, type }: { file: File; type: string }
    ) {
      const { data, success, message } = await api.uploadEsimDirectoryImages(
        type,
        file
      );
      return { data, success, message };
    },
    async actionWithDirectory(
      _: any,
      {
        data: reqBody,
        link,
        id
      }: { data: IEsimDirectoryDefault; link: string; id: number }
    ) {
      if (!id) {
        const { data, success, message } = await api.addEsimDirectory(
          reqBody,
          link
        );
        return { data, success, message };
      } else if (!reqBody) {
        const { data, success, message } = await api.deleteEsimDirectory(
          link,
          id
        );
        return { data, success, message };
      } else {
        const { data, success, message } = await api.updateEsimDirectory(
          reqBody,
          link,
          id
        );
        return { data, success, message };
      }
    }
  }
};
